import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useAuth } from '../AuthContext'; // Import useAuth to use the signOut function

const Navigation1 = ({ className = "" }) => {
  const navigate = useNavigate();
  const { signOut } = useAuth(); // Destructure signOut from useAuth

  const onLogoutTextClick = useCallback(() => {
    signOut(); // Call the signOut function from AuthContext
    navigate("/"); // Navigate to the login page
  }, [signOut, navigate]);

  return (
      <footer
          className={`self-stretch shadow-[0px_4px_21px_rgba(0,_0,_0,_0.1)] bg-white flex flex-row items-start justify-between pt-[21px] pb-[22px] pr-[159px] pl-[108px] box-border max-w-full gap-[20px] text-left text-5xl text-darkslateblue font-montserrat mq825:flex-wrap mq825:pl-[54px] mq825:pr-[79px] mq825:box-border mq450:pl-5 mq450:pr-5 mq450:box-border ${className}`}
      >
        <div className="h-[99px] w-[1438px] relative shadow-[0px_4px_21px_rgba(0,_0,_0,_0.1)] bg-white hidden max-w-full" />
        <div className="flex flex-row items-start justify-start py-0 pr-[33px] pl-0 gap-[27px]">
          <img
              className="h-14 w-14 relative object-cover z-[1]"
              loading="lazy"
              alt=""
              src="/interface-essentialhome@2x.png"
          />
          <div className="flex flex-col items-start justify-start pt-3.5 px-0 pb-0">
            <div className="relative inline-block min-w-[75px] z-[1] mq450:text-lgi">
              Home
            </div>
          </div>
        </div>
        <div className="flex flex-row items-start justify-start gap-[27px]">
          <img
              className="h-14 w-14 relative z-[1]"
              loading="lazy"
              alt=""
              src="/interface-essentialtrendingup.svg"
          />
          <div className="flex flex-col items-start justify-start pt-3.5 px-0 pb-0">
            <div className="relative inline-block min-w-[105px] z-[1] mq450:text-lgi">
              Progress
            </div>
          </div>
        </div>
        <div className="flex flex-row items-start justify-start gap-[27px]">
          <img
              className="h-14 w-14 relative z-[1]"
              loading="lazy"
              alt=""
              src="/interface-essentiallock-1.svg"
          />
          <div className="flex flex-col items-start justify-start pt-3.5 px-0 pb-0">
            <div
                className="relative inline-block min-w-[87px] cursor-pointer z-[1] mq450:text-lgi"
                onClick={onLogoutTextClick}
            >
              Logout
            </div>
          </div>
        </div>
      </footer>
  );
};

Navigation1.propTypes = {
  className: PropTypes.string,
};

export default Navigation1;
