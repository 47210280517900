import PropTypes from "prop-types";

const MediumUser = ({ className = "" }) => {
  return (
    <div
      className={`rounded-mini overflow-hidden flex flex-col items-start justify-start pt-[27px] pb-[25px] pr-12 pl-[51px] gap-[26px] z-[1] text-center text-sm text-text-main-text font-montserrat border-[2px] border-solid border-gray-200 mq450:p-5 mq450:box-border ${className}`}
    >
      <div className="w-6 h-[34.3px] relative overflow-hidden shrink-0 hidden" />
      <div className="w-6 h-[34.3px] relative overflow-hidden shrink-0 hidden" />
      <div className="flex flex-row items-start justify-start py-0 pr-[26px] pl-[23px]">
        <img
          className="h-[161px] w-[161px] relative object-cover"
          loading="lazy"
          alt=""
          src="/avatar@2x.png"
        />
      </div>
      <div className="flex flex-col items-start justify-start gap-[10px]">
        <div className="flex flex-row items-start justify-start py-0 pr-[58px] pl-14">
          <div className="relative inline-block min-w-[96px]">
            Your Clinician
          </div>
        </div>
        <div className="relative text-5xl tracking-[0.05em] leading-[25px] font-semibold mq450:text-lgi mq450:leading-[19px]">
          Gabrielle Berne
        </div>
      </div>
      <div className="flex flex-row items-start justify-start pt-0 pb-[5px] pr-[22px] pl-[19px]">
        <button className="cursor-pointer [border:none] py-[19px] px-6 bg-color-system-secondary-colors-yellow-500 rounded-11xl flex flex-row items-start justify-start whitespace-nowrap hover:bg-goldenrod">
          <div className="relative text-base leading-[18px] font-medium font-montserrat text-text-main-text text-left inline-block min-w-[120px]">
            Give Feedback
          </div>
        </button>
      </div>
      <div className="flex flex-row items-start justify-start py-0 pr-[35px] pl-8 text-base text-darkslateblue">
        <div className="flex flex-row items-start justify-start gap-[3px]">
          <img
            className="h-6 w-6 relative min-h-[24px]"
            loading="lazy"
            alt=""
            src="/interface-essentiallock.svg"
          />
          <div className="flex flex-col items-start justify-start pt-0.5 px-0 pb-0">
            <div className="relative font-semibold inline-block min-w-[116px]">
              Assign games
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

MediumUser.propTypes = {
  className: PropTypes.string,
};

export default MediumUser;
