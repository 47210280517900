import FormContainer from "../components/form-container";

const MoveMendLoginPatientTable = () => {
  return (
    <div className="w-full relative [background:linear-gradient(#ea7b7d,_#ea7b7d),_#fff] overflow-hidden flex flex-row items-start justify-start pt-[167.6px] pb-[160.6px] pr-[30px] pl-[66px] box-border gap-[88.4px] leading-[normal] tracking-[normal] mq750:gap-[44px] mq750:pl-[33px] mq750:box-border mq450:gap-[22px] mq1125:flex-wrap">
      <div className="w-[440px] flex flex-col items-start justify-start pt-[32.4px] px-0 pb-0 box-border min-w-[440px] max-w-full mq750:min-w-full mq1125:flex-1">
        <div className="self-stretch flex flex-col items-end justify-start gap-[28.6px] max-w-full">
          <img
            className="w-[421px] relative max-h-full object-contain max-w-full"
            loading="lazy"
            alt=""
            src="/blacklogo-transbg-3@2x.png"
          />
          <FormContainer />
        </div>
      </div>
      <img
        className="h-[633.8px] flex-1 relative max-w-full overflow-hidden min-w-[530px] mq750:min-w-full"
        loading="lazy"
        alt=""
        src="/undraw-workout-gcgu-1.svg"
      />
    </div>
  );
};

export default MoveMendLoginPatientTable;
