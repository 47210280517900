import React, { createContext, useContext, useState, useEffect } from 'react';
import supabase from './supabase/supabaseClient';
import bcrypt from 'bcryptjs';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const loadUser = async () => {
            const sessionUser = JSON.parse(localStorage.getItem('user'));
            console.log('Initial sessionUser from localStorage:', sessionUser);
            if (sessionUser) {
                setUser(sessionUser);
            }
            setLoading(false);
        };
        loadUser();
    }, []);

    const signIn = async (username, password) => {
        console.log('Signing in with username:', username);
        const { data, error } = await supabase
            .from('users')
            .select('id, username, password_hash')
            .eq('username', username)
            .single();

        if (error || !data || !bcrypt.compareSync(password, data.password_hash)) {
            console.error('Sign in error:', error);
            throw new Error('Invalid username or password');
        }

        const user = { id: data.id, username: data.username };
        console.log('User signed in:', user);
        localStorage.setItem('user', JSON.stringify(user));
        setUser(user);
        return user;
    };

    const signUp = async (username, password) => {
        console.log('Signing up with username:', username);
        const salt = bcrypt.genSaltSync(10);
        const passwordHash = bcrypt.hashSync(password, salt);

        const { data, error } = await supabase
            .from('users')
            .insert([{ username, password_hash: passwordHash }])
            .select();

        if (error) {
            console.error('Sign up error:', error);
            throw error;
        }

        const newUser = data[0];
        console.log('User signed up:', newUser);
        return newUser;
    };

    const signOut = () => {
        console.log('Signing out');
        localStorage.clear();
        setUser(null);
    };

    console.log('Current user state:', user);
    console.log('Loading state:', loading);

    return (
        <AuthContext.Provider value={{ user, signIn, signUp, signOut, loading }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
