import React, { useEffect, useState } from "react";
import { supabase } from '../supabase/supabaseClient';
import FrameComponent from "../components/frame-component";
import MediumUser from "../components/medium-user";
import Navigation1 from "../components/navigation1";

const MedicalDashboard = () => {
    const [message, setMessage] = useState('');

    useEffect(() => {
        const handleGameData = async () => {
            const gameDataString = localStorage.getItem('gameData');
            const userDataString = localStorage.getItem('userData');

            console.log('Retrieved gameDataString:', gameDataString);
            console.log('Retrieved userDataString:', userDataString);

            if (gameDataString && userDataString) {
                const gameData = JSON.parse(gameDataString);
                const userData = JSON.parse(userDataString);

                console.log('Parsed gameData:', gameData);
                console.log('Parsed userData:', userData);

                try {
                    const { data, error } = await supabase
                        .from('gameSessions')
                        .insert({
                            user_id: userData.id,
                            username: userData.username,
                            game_id: gameData.game_id,
                            score: gameData.score,
                            duration: gameData.duration
                        });

                    if (error) throw error;

                    console.log('Game session saved:', data);
                } catch (error) {
                    console.error('Error saving game session:', error.message);
                    setMessage('Error saving game data. Please try again.');
                }

                localStorage.removeItem('gameData');
            }
        };

        handleGameData();
    }, []);


    return (
        <div className="w-full relative bg-floralwhite overflow-hidden flex flex-col items-start justify-start pt-[39px] px-0 pb-0 box-border gap-[390px] leading-[normal] tracking-[normal] mq825:gap-[195px] mq450:gap-[97px]">
            {message && <div className="message">{message}</div>}
            <section className="self-stretch flex flex-row items-start justify-start py-0 pr-[47px] pl-[45px] box-border max-w-full mq825:pl-[22px] mq825:pr-[23px] mq825:box-border">
                <div className="flex-1 flex flex-row flex-wrap items-start justify-start gap-[76px] max-w-full mq675:gap-[38px] mq450:gap-[19px]">
                    <FrameComponent />
                    <MediumUser />
                </div>
            </section>
            <Navigation1 />
        </div>
    );
};

export default MedicalDashboard;