import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useAuth } from '../AuthContext';

const FormContainer = ({ className = "" }) => {
    const navigate = useNavigate();
    const { signIn, signUp } = useAuth();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [isSignUp, setIsSignUp] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!username || !password) {
            alert("Username and password are required.");
            setIsSubmitting(false);
            return;
        }
        setIsSubmitting(true);
        try {
            if (isSignUp) {
                await signUp(username, password);
                alert('Sign up successful!');
                setIsSignUp(false);
            } else {
                const user = await signIn(username, password);
                console.log('User signed in:', user); // Debugging log
                alert('Sign in successful!');
                navigate("/medical-dashboard");
            }
        } catch (error) {
            alert(`Error: ${error.message}`);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div
            className={`self-stretch rounded-8xl bg-gainsboro-200 flex flex-col items-start justify-start pt-10 px-[45px] pb-[33px] box-border gap-[26px] max-w-full text-left text-base text-white font-montserrat mq450:pt-[26px] mq450:px-5 mq450:pb-[21px] mq450:box-border ${className}`}
        >
            <form onSubmit={handleSubmit} className="self-stretch flex flex-col items-start justify-start gap-[26px]">
                <div className="self-stretch h-20 flex flex-col items-start justify-start gap-[10px]">
                    <label htmlFor="username" className="relative z-[1]">Let’s start with your name</label>
                    <input
                        id="username"
                        type="text"
                        className="self-stretch flex-1 relative max-w-full overflow-hidden max-h-full z-[1] rounded-[5px] p-[10px] bg-white text-black"
                        style={{
                            width: "348px",
                            height: "50px",
                            flexShrink: 0,
                            backgroundColor: "rgba(255, 255, 255, 0.3)", // 30% opaque white background
                        }}
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        aria-label="Username"
                        disabled={isSubmitting}
                    />
                </div>
                <div className="self-stretch flex flex-col items-start justify-start pt-0 px-0 pb-[5px] gap-[10px]">
                    <label htmlFor="password" className="relative inline-block min-w-[78px] z-[1]">Password</label>
                    <input
                        id="password"
                        type="password"
                        className="self-stretch h-[50px] relative z-[1] rounded-[5px] p-[10px] bg-white text-black"
                        style={{
                            width: "348px",
                            height: "50px",
                            flexShrink: 0,
                            backgroundColor: "rgba(255, 255, 255, 0.3)", // 30% opaque white background
                        }}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        aria-label="Password"
                        disabled={isSubmitting}
                    />
                </div>
                <div className="self-stretch flex flex-row items-start justify-center py-0 pr-[3px] pl-0">
                    <button
                        type="submit"
                        className="cursor-pointer [border:none] py-[19px] px-[59px] bg-darkslateblue rounded-11xl flex flex-row items-start justify-start whitespace-nowrap z-[1] hover:bg-plum"
                        disabled={isSubmitting}
                    >
                        <div className="relative text-base leading-[18px] font-medium font-montserrat text-white text-left inline-block min-w-[51px]">
                            {isSignUp ? 'Sign Up' : 'Log In'}
                        </div>
                    </button>
                </div>
                <div className="self-stretch flex flex-row items-start justify-center py-0 pr-0.5 pl-0 text-center">
                    <button
                        type="button"
                        className="relative font-semibold inline-block min-w-[64px] whitespace-nowrap z-[1] text-white font-montserrat"
                        onClick={() => setIsSignUp(!isSignUp)}
                        disabled={isSubmitting}
                        style={{
                            backgroundColor: 'transparent',
                            border: 'none',
                            cursor: 'pointer',
                            padding: 0
                        }}
                    >
                        {isSignUp ? 'Switch to Log In' : 'Switch to Sign Up'}
                    </button>
                </div>
            </form>
        </div>
    );
};

FormContainer.propTypes = {
    className: PropTypes.string,
};

export default FormContainer;
